import React from "react";
import PropTypes from "prop-types";
import NavbarLink from "./NavbarLink";
import styles from "./Navbar.module.css";

const MobileLinks = React.memo(({ isMenuOpen }) => {
  return (
    <div
      className={`${styles.mobileLinkContainer} ${
        isMenuOpen ? styles.collapseNavbarMenu : styles.showNavbarMenu
      }`}
    >
      <NavbarLink href="/" linkText="HOME" />
      <NavbarLink href="/reviews" linkText="REVIEWS" />
      <NavbarLink href="/packages" linkText="PACKAGES" />
      <NavbarLink href="/contactus" linkText="CONTACT US" />
      <NavbarLink href="/#aboutus" linkText="ABOUT US" />
    </div>
  );
});
export default MobileLinks;
MobileLinks.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};
