import Reviews from "../Home/Reviews";
import REVIEW_IMAGE1 from "../../assets/Reviews/default.png";
import REVIEW_IMAGE3 from "../../assets/Reviews/kousiksen.png";
import REVIEW_IMAGE2 from "../../assets/Reviews/sayansaha.png";
import { randomElement } from "../../helper";

const review1 = `Arrangements made by Abhik was really good. He was available through phone whenever he needed any support. Our guide and driver Prakash was a gentleman.
Food arrangement was great!
Over all our trip was great because of Safarnama !`;

const review2 =
  "Thanks #_SAFARNAMA for this wonderful tour😍😍 Great reliable service. No hidden charges. hotels are really great as promised on all location with good food. car service is also great. One stop to perfect tour plan";

const review3 = `Thank you #Safarnama for organizing such a beautiful and memorable tour at North Sikkim. It was a comfortable tour for you guys only. Team Safarnama did a wonderful job for us. Organizing hotel, car everything was just awesome. Thanks to Ranjan bhaiya for safe riding throughout the tour. I am highly recomending Safarnama for your next tour. Believe me you will never regret.`;

const review4 = `Went to Mandarmani in the month of December. At the time of really high rates, Safarnama provided us a great resort at a very reasonable and affordable price.  The resort was at a 2 minute walking distance from the beach and the service was great too. I recommend contacting them if you want great places at affordable rates. Thank you Safarnama for making the trip awesome.`;

const COLOR_ARRAY = ["cyan", "yellow", "pink"];
const PATH = {
  cyan:
    "M-1.12,66.61 C327.88,-52.78 268.06,194.89 501.69,109.03 L500.00,260.00 L0.00,260.00 Z",
  yellow:
    "M-1.12,92.27 C327.88,-52.78 384.87,212.66 502.82,118.91 L500.00,260.00 L0.00,260.00 Z",
  pink:
    "M-1.12,81.41 C247.74,-28.13 256.20,186.02 501.12,69.56 L500.00,260.00 L0.00,260.00 Z",
};

const items = [
  {
    name: "Soma Mitra",
    alt: "Soma Mitra",
    src: REVIEW_IMAGE1,
    review: review1,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 5,
  },
  {
    name: "Sayan Saha",
    alt: "Sayan Saha",
    src: REVIEW_IMAGE2,
    review: review2,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 5,
  },
  {
    name: "Kousik Sen",
    alt: "Kousik Sen",
    src: REVIEW_IMAGE3,
    review: review3,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 5,
  },
  {
    name: "Rohit Mondal",
    alt: "Rohit Mondal",
    src:
      "https://lh3.googleusercontent.com/a-/AOh14Gga7LM0bIgU3ZRc8nL99al2EcMNwNYtsp3QccSH=w120-h120-p-rp-mo-br100",
    review: review4,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 5,
  },
  {
    name: "Rosy Aditi",
    alt: "Rosy Aditi",
    src:
      "https://lh3.googleusercontent.com/a-/AOh14GgUfjWp7pkjcVXODUAgoLxxYS6IvE_8a8Ygx8irKA=w120-h120-p-rp-mo-br100",
    review:
      'We went north Sikkim with them. Rooms and foods are good. But in Lachung ,the hotel staffs were very rude and they fraud us. Be aware from that hotel, name "Blizzard". Overall I am giving four stars because of the good driver,good food and hotel with good view.',
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 4,
  },
  {
    name: "Aaheli Mitra",
    alt: "Aaheli Mitra",
    src:
      "https://lh3.googleusercontent.com/a-/AOh14Gj0jjVZYb67EQY_NLxkopE_VQW4bJLZOqSqXzwyrg=w120-h120-p-rp-mo-br100",
    review: `Thanks SAFARNAMA  for the wonderful trip arrangement.
      We had a 3 nights and 4days trip to DOOARS.
      SAFARNAMA helped us with everything that too just over ip calls and whatsapp messages.
      From Team SAFARNAMA, it was Shubhranjan who helped us with everything starting with planning the trip, fooding and staying at a clean and affordable resort. Honestly speaking, he is so approachable that for any query the only thing I had to do was pick up my phone to either text him or call him.
      I will start with the details of my trip:
      1. Me and my family members were picked up in a WAGON-R car as we were only 3 members. You will definitely get a bigger vehicle if you are more in number.
      2. We stayed at Moorti, so we were brought to Resort Vinayak Murti, which I must say was a complete bliss. I was provided with pictures of the resort earlier...but to be there with such beautiful surroundings...I completely loved it.
      3. We opted for a non-ac double-bed room and took an extra bed for the third person. We didn't want to get an extra room for a single person and this was definitely a plus point of making a booking with SAFARNAMA. They made it possible.
      4. We were provided with clean room and bedding.
      5. We were provided with complimentary lunch on the day of arrival and complimentary bed tea for every morning. For the rest of the days, the breakfast and dinner were included in the package cost already, so you could eat anything and everything of your choice from the menu 🤭🤭.
      6. Our package trip included:
      a) 1st day - Jhalong,Paren,Bindu, Apple Stone, Murti river, Samsing, Gairibas.
      b) 2nd day - Lal Jhamela Basti, Chapramari Forest, Chengmari Tea Estate, Dharanipur Tea Estate.
      c) 3rd day - Gojoldoba and drop to station
      I have attached some photos so that you guys know what we experienced.
      
      I would highly recommend SAFARNAMA for any of your trip bookings. Just go for it,  you won't regret.`,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 4,
  },
  {
    name: "Aryan Banerjee",
    alt: "Aryan Banerjee",
    src:
      "https://lh3.googleusercontent.com/a-/AOh14GiOwgK7vmhzN0BICQvOrHuaa4UNqd3tS9c0qM4fdw=w120-h120-p-rp-mo-ba4-br100",
    review: `We have contacted Safarnama for 6 days 5 Night tour of Simla & Kullu Manali for 4 persons.

    As the contact and everything was through online mode(we don't even know who are they, their office & location), we were in doubt but I am so glad that we have contacted them for our travel planner and organizer.
    
    Our tour was planned from Chandigarh to Chandigarh (chandigarh-shimla-kullu manali-chandigarh) and it was really value for money.
    
    On time they given us taxi driver's (our travel partner, through out the tour) number. Pick up was as per our convenient time and we got awesome view hotel at Shimla, food also delicious over there(breakfast and dinner was included in package). We had great outing over there(stayed for 2nights) and so in kullu manali(3 nights). Manali hotel was not in a great location, and food also not so good but the hotel was very clean & tidy and view from the balcony was mind blowing. Adventure sports and rothang was excluded from out package but the hotels and sightseeing were really worthy.
    
    Places covered :
    1. Simla mall and market, Shimla Christ Church, Kufri, Fagu (separate charges if you want to go to fagu by horse riding)
    2. Manali local market, Hadimba Temple, Vasisth, Tibetian Monestry, Solang Valley ( rothang pass you can include with seperate charges), Kullu market, Pandoah Dam
    
    Pros:
    1. Value for money
    2. They are responsive and ready to help
    
    Advice to Safarnama :
    1. Please do inform some small small information to us or inform hotel or guide before.
    Example- on whose name the hotels are booked can be informed before.
    2. What are all extra charges/ pass required can be informed to us before time.
    
    To the readers:
    If you are planning any tour for couple/ for honeymoon/ for friends or for family then don't waste you time by googling here and there, just contact Safarnama. You will get the best deal and tour will be memorable.`,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 5,
  },
  {
    name: "Santu Jana",
    alt: "Santu Jana",
    src:
      "https://lh3.googleusercontent.com/a-/AOh14GgQRfkPFrwcx9AjhnuKtzh5DelFq87vHiFFRCGDPw=w120-h120-p-rp-mo-br100",
    review: `We have completed a Trip in Himachal Pradesh in Nov 21 with the help of Safarnama. Their rate is very cheap, have no any hidden charges, their service is too much great, also the behaviour is very nice. Thanks to Safarnama.`,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 5,
  },
  {
    name: "Sithun Karmakar",
    alt: "Sithun Karmakar",
    src:
      "https://lh3.googleusercontent.com/a-/AOh14GixIgAY11o0qKjWczNMiXAAcRIjtDsGl8s3ge6aqmU=w120-h120-p-rp-mo-ba4-br100",
    review: `From the very beginning to end of our dooars trip.
    Made easy trips, guidance, advices,
    Affordable, and follow up regularly about trip.
    So overall GOOD experience.
    Looking forward for more...
    Best of luck 👍
    Will surely recommend 👍👍👍👍 Safarnama.`,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 4,
  },
  {
    name: "Shreya ghosh",
    alt: "Shreya ghosh",
    src:
      "https://lh3.googleusercontent.com/a-/AOh14GhWsA42kWSF5sQi19qtYkGPom74B5fsaiwWMdR70g=w120-h120-p-rp-mo-br100",
    review: `Great reliable service.
    No hidden charges.
    hotels are really great as promised on all location with good food.
    car service is also great.
    One stop to perfect tour plan.`,
    color: randomElement(COLOR_ARRAY),
    svgPath: PATH[randomElement(COLOR_ARRAY)],
    rating: 5,
  },
];

const AllReviews = () => {
  return <Reviews items={items} allReviews />;
};

export default AllReviews;
