import React, { useState } from "react";
import PinkLeftQuote from "../../assets/Reviews/pink-left-quote.png";
import YellowLeftQuote from "../../assets/Reviews/yellow-left-quote.png";
import CyanLeftQuote from "../../assets/Reviews/cyan-left-quote.png";
import styles from "./reviews.module.css";
import Image from "../Image";
import Modal from "../Modal";
import StarRating from "../StarRating";

const ReviewCard = ({
  src,
  webpImage,
  alt,
  name,
  review,
  color,
  svgPath,
  rating,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };
  let leftQuote =
    color === "cyan"
      ? CyanLeftQuote
      : color === "yellow"
      ? YellowLeftQuote
      : PinkLeftQuote;
  return (
    <>
      <div
        className={`${styles.individualReviewCard} ${
          styles[`review-${color}`]
        } `}
        onClick={() => setModalOpen(true)}
      >
        <StarRating rating={rating} />
        <Image src={leftQuote} alt="left-quote" />
        <p className={styles.review}>{review.slice(0, 200)}...</p>
        <div className={styles.displayPicContainer}>
          <Image src={src} webpImage={webpImage} alt={alt} />
        </div>
        <div className={styles.curvature}>
          <p>{name}</p>
        </div>
        <div className={styles.wave}>
          <svg viewBox="0 0 500 260">
            <path
              d={svgPath}
              className={`${styles.path} ${styles[`path-${color}`]}`}
            />
          </svg>
        </div>
      </div>

      <Modal visible={isModalOpen} onClick={closeModal}>
        <div className={styles.reviewCardModalContainer}>
          <div className={styles.reviewCardModalLeftContainer}>
            <div className={styles.displayPicContainer}>
              <Image src={src} webpImage={webpImage} alt={alt} />
            </div>
            <div className={styles.curvature}>
              <p>{name}</p>
            </div>
          </div>
          <div className={styles.reviewCardModalRightContainer}>
            <Image src={leftQuote} alt="left-quote" />
            <p className={styles.full_review}>{review}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ReviewCards = ({ items }) => {
  return (
    <div className={styles.reviewCardContainer}>
      {items.map(({ name, alt, src, review, color, svgPath, rating }) => (
        <ReviewCard
          name={name}
          alt={alt}
          src={src}
          review={review}
          color={color}
          svgPath={svgPath}
          rating={rating}
        />
      ))}
    </div>
  );
};
export default ReviewCards;
