export const getExtension = (url) => {
  if (typeof url !== "string") {
    return null;
  }

  const parts = url.split("/");
  const lastPart = parts[parts.length - 1];
  const nameParts = lastPart.split(".");
  const extension = nameParts[nameParts.length - 1];
  return extension;
};

export const openUrl = (url) => {
  window.open(url);
};

export const randomElement = (items) => {
  return items[Math.floor(Math.random() * items.length)];
};
