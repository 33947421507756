import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from "../Components/Navbar";
import loadable from "@loadable/component";
import withTracker from "../Components/withTracker";
import Fallback from "../Components/Fallback";
import AllReviews from "./AllReviews";

const Home = loadable(() => import("./Home"));
const PrivacyPolicy = loadable(() => import("./PrivacyPolicy"));
const Packages = loadable(() => import("./Packages/index"));
const Footer = loadable(() => import("../Components/Footer"));
const Contactus = loadable(() => import("./Contactus/index"));

export default function RootPage() {
  return (
    <>
      <Navbar />
      <Suspense fallback={<Fallback />}>
        <Switch>
          <Route exact path="/" component={withTracker(Home)} />
          <Route
            exact
            path="/privacy_policy"
            component={withTracker(PrivacyPolicy)}
          />
          <Route exact path="/reviews" component={withTracker(AllReviews)} />
          <Route exact path="/packages" component={withTracker(Packages)} />
          <Route exact path="/contactus" component={withTracker(Contactus)} />
        </Switch>
      </Suspense>
      <Footer />
    </>
  );
}
