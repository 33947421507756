import React from "react";
import NavbarLink from "./NavbarLink";
import styles from "./Navbar.module.css";

const DesktopLinks = () => {
  return (
    <div className={styles.desktopLinkContainer} data-testid="desktopLink">
      <NavbarLink href="/" linkText="HOME" />
      <NavbarLink href="/reviews" linkText="REVIEWS" />
      <NavbarLink href="/packages" linkText="PACKAGES" />
      <NavbarLink href="/contactus" linkText="CONTACT US" />
      <NavbarLink href="/#aboutus" linkText="ABOUT US" />
    </div>
  );
};
export default DesktopLinks;
