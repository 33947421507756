import React from "react";
import LOGO from "../../assets/Logo/logo.png";
import styles from "./Navbar.module.css";

export const Logo = ({ prependData, appendData }) => {
  return (
    <div className={styles.logoContainer}>
      {prependData && (
        <span style={{ marginRight: "20px", fontWeight: "bold" }}>
          {prependData}
        </span>
      )}

      <div className={styles.abLogoImageContainer}>
        <img src={LOGO} alt="Safarnama Logo" className={styles.abLogoImage} />
      </div>
      <span
        className={`footerButtonText ${styles.logoName}`}
        style={prependData ? { color: "white" } : {}}
      >
        SAFARNAMA
      </span>
      {appendData && <span>{appendData}</span>}
    </div>
  );
};
