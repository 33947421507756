import React from "react";
import ButtonLinks from "../../../Components/Footer/buttonLinks";
import Icon from "../../../Components/Icon";
import ReviewCards from "../../../Components/ReviewCards";
import styles from "./reviews.module.css";

const Reviews = ({ items, allReviews = false }) => {
  const child = (
    <div className={styles.subText}>
      <span style={{ textDecoration: "underline" }}>See more reviews</span>
      <button type="button" onClick={() => {}} className={styles.showAll}>
        <Icon name="chevron-right" size="2x" />
      </button>
    </div>
  );

  return (
    <div className={styles.container}>
      {!allReviews ? (
        <div className={styles.reviewsHeader}>REVIEWS</div>
      ) : (
        <h1 className={styles.allReviewsHeader}>
          Have a look at what our loyal customers have to say about us!
        </h1>
      )}
      <ReviewCards items={items} />

      {!allReviews && <ButtonLinks text={child} url="/reviews" />}
      {allReviews && (
        <div className={styles.reviewsFooter}>
          ...100+ reviews on Google. Check them out{" "}
          <a
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.google.com/search?q=safarnama%20tourism&ei=poC9Yr7yJoKgseMP2eKmkA0&oq=safarnama+to&gs_lcp=Cgdnd3Mtd2l6EAMYATIFCAAQkQIyBQgAEIAEMggIABAeEBYQCjoHCAAQRxCwAzoKCAAQRxCwAxDJAzoFCC4QgAQ6CwguEIAEEMcBEK8BSgQIQRgASgQIRhgAUKQGWPYYYKwiaAFwAHgAgAHKAYgBohOSAQYwLjEzLjKYAQCgAQHIAQjAAQE&sclient=gws-wiz&tbs=lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=11164844094360519832&lqi=ChFzYWZhcm5hbWEgdG91cmlzbUixpKPg8bSAgAhaGxAAEAEYABgBIhFzYWZhcm5hbWEgdG91cmlzbZIBDXRvdXJfb3BlcmF0b3KaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUmhORGRUTjNwUlJSQUKqARkQASoVIhFzYWZhcm5hbWEgdG91cmlzbSgA&ved=2ahUKEwiMh_XbgtX4AhXuS2wGHSf8AjUQvS56BAgEEAE&sa=X&rlst=f#rlfi=hd:;si:11164844094360519832,l,ChFzYWZhcm5hbWEgdG91cmlzbUixpKPg8bSAgAhaGxAAEAEYABgBIhFzYWZhcm5hbWEgdG91cmlzbZIBDXRvdXJfb3BlcmF0b3KaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUmhORGRUTjNwUlJSQUKqARkQASoVIhFzYWZhcm5hbWEgdG91cmlzbSgA;mv:[[36.6318358,89.22091069999999],[11.617383799999999,71.60507129999999]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2"
          >
            here
          </a>
          .
        </div>
      )}
    </div>
  );
};

export default Reviews;
