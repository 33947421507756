import { useState } from "react";
import "./index.css";

const StarRating = ({ rating }) => {
  //   const [rating, setRating] = useState(0);
  //   const [hover, setHover] = useState(0);
  return (
    <div className="star-rating">
      {[...Array(rating)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            // className={index <= (hover || rating) ? "on" : "off"}
            className="on"
            // onClick={() => setRating(index)}
            // onMouseEnter={() => setHover(index)}
            // onMouseLeave={() => setHover(rating)}
          >
            <span className="star">&#9733;</span>
          </button>
        );
      })}
      {rating < 5 &&
        [...Array(5 - rating)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              // className={index <= (hover || rating) ? "on" : "off"}
              className="off"
              // onClick={() => setRating(index)}
              // onMouseEnter={() => setHover(index)}
              // onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
    </div>
  );
};

export default StarRating;
