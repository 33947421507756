import React from 'react';
import styles from './modal.module.css';

const Modal = ({
  visible = false,
  progress = false,
  onClick,
  children,
  background,
  className,
  closeButton = true
}) => {
  const handleKeyPress = (event) => {
    if (event.keyCode === 13 && typeof onClick === 'function') {
      onClick();
    }
  };

  return visible ? (
    <section
      className={`${styles.modal} ${className}`}
      role="status"
      onClick={!closeButton ? onClick : null}
      id="modal">
      <div
        className={`${styles.modal_content} ${
          background === 'none' ? styles.backgroundNone : null
        }  `}>
        <div
          className={`${styles.modal_progress} ${
            progress
              ? styles.modal_progress_active
              : styles.modal_progress_inactive
          }`}>
          <div className={styles.modal_progress_bar} />
        </div>
        {closeButton && (
          <div
            className={styles.close}
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyPress}>
            x
          </div>
        )}

        {children}
      </div>
    </section>
  ) : null;
};

export default Modal;
